import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HxpToastService } from '@hxp/shared/common';
import { Observable, Subject } from 'rxjs';

export interface EventInformation {
  eventType: string;
  eventData?: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class EventDataHandlerService {
  eventInformation$: Observable<EventInformation>;
  private _eventInformationSubject$: Subject<EventInformation> = new Subject<EventInformation>();

  constructor(
    private _toastService: HxpToastService,
    private _router: Router,
  ) {
    this.eventInformation$ = this._eventInformationSubject$.asObservable();
  }

  decodeUrlEventData(base64String: string): unknown {
    // Due to high possibility that string will be corrupted, we need to be sure it was parsed
    try {
      return JSON.parse(atob(base64String));
    } catch (e) {
      this._toastService.error('Error while parsing URL event data, please contact administrator');
      void this._router.navigate(['']);
      return;
    }
  }

  emitEventInformation(eventInformation: EventInformation): void {
    this._eventInformationSubject$.next(eventInformation);
  }
}
