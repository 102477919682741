import { Component, inject, OnInit } from '@angular/core';
import { ShellSettingsService } from '@hxp/kernel';
import { OnboardingUrlEventDataService } from './services/onboarding-url-event-data.service';

@Component({
  selector: 'hxp-customer-onboarding-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly _shellSettings = inject(ShellSettingsService);
  private readonly _onboardingUrlEventDataService = inject(OnboardingUrlEventDataService);

  ngOnInit() {
    this._shellSettings.forceLightTheme();
    this._onboardingUrlEventDataService.handleSignTerms();
  }
}
